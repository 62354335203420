@import "../../styles/mixins.scss";

.footer {
  display: flex;
  gap: var(--spacing-6);
  align-items: center;
  margin-top: var(--spacing-10);

  p,
  a {
    margin-top: 0;
    font-weight: var(--font-weight-normal);
    font-size: var(--typography-base-sizes-small-font-size);
    line-height: var(--typography-base-sizes-small-line-height);
    color: var(--base-muted-foreground);
    outline-color: var(--base-ring);
  }

  .terms {
    text-decoration: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-underline-offset: 2px;
    cursor: pointer;
  }
}
