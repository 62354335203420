@import "src/styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100dvh;
  padding: var(--spacing-10) var(--spacing-4) var(--spacing-6);

  @include media(notMobile) {
    margin: 0 auto;
    max-width: var(--max-width-max-w-3xl);
  }

  h1 {
    margin-top: var(--spacing-8);
    font-weight: var(--font-weight-bold);
    font-size: var(--typography-base-sizes-2x-large-font-size);
    line-height: var(--typography-base-sizes-2x-large-line-height);
    color: var(--base-foreground);
  }

  h2,
  h3,
  p,
  .textContainer {
    margin-top: var(--spacing-2);
  }

  h2 {
    font-weight: var(--font-weight-bold);
    font-size: var(--typography-base-sizes-large-font-size);
    line-height: var(--typography-base-sizes-large-line-height);
    color: var(--base-foreground);
  }

  h3 {
    font-weight: var(--font-weight-semibold);
    font-size: var(--typography-base-sizes-base-font-size);
    line-height: var(--typography-base-sizes-base-line-height);
    color: var(--base-foreground);
  }

  p,
  .textContainer {
    font-weight: var(--font-weight-normal);
    font-size: var(--typography-base-sizes-base-font-size);
    line-height: var(--typography-base-sizes-base-line-height);
    color: var(--base-muted-foreground);
  }

  ul,
  ol {
    margin-bottom: var(--spacing-4);
    padding-left: 38px;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  li {
    list-style-type: inherit;
    margin: 0;
  }

  span {
    position: relative;
    text-decoration: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-underline-offset: 2px;
    color: var(--base-foreground);
    cursor: pointer;
  }

  .logo {
    display: flex;
    width: max-content;
    outline-color: var(--base-ring);
    cursor: pointer;

    &:focus-visible {
      outline: 1px solid var(--base-ring);
      outline-width: 2px;
    }
  }

  .footer {
    align-self: center;
  }
}
